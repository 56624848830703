import React from 'react';
import App from './App';
import Servicos from './Servicos';
import Localizacao from './Localizacao';
import QuemSomos from './QuemSomos';
import Projetos from './Projetos';
import Contato from './Contato';
import Erro from './Erro';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/" exact={true} component={App} />
            <Route path="/servicos" component={Servicos} />
            <Route path="/localizacao" component={Localizacao} />
            <Route path="/quemsomos" component={QuemSomos} />
            <Route path="/projetos" component={Projetos} />
            <Route path="/projetos" component={Contato} />
            <Route path="/contato" component={Contato} />
            <Route path='/erro'  component={Erro} />
        </Switch>
    </ BrowserRouter>,    
document.getElementById('root'));

serviceWorker.unregister();