import React from 'react';
import './style.scss';

const Maps = ({width, height}) => {
	return (
        <section id="location">   
			<div className="maps">          
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3705.18648615911!2d-48.16239088449947!3d-21.773042385599975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b8f152d8b2515f%3A0x81a848263b4ee73d!2sMarcenaria%20Magno!5e0!3m2!1spt-PT!2spt!4v1621101905464!5m2!1spt-PT!2spt" 
					width={width} 
					height={height}  
					allowFullScreen
					loading="lazy"/>
			</div>
        </section>		
	);
};

export default Maps;

