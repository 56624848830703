import React from 'react';
import Error from '../components/error';
import Header from '../components/header';
import FooterContainer from '../components/footer';

const Erro = () => {
  return (
    <>
        <Header />
        <Error />
        <FooterContainer />
    </>
  );
}

export default Erro;