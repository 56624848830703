import React from 'react';
import '../../posts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Box = ({title, link, description, image, classe }) => {

    const { width, height, url, alt } = image;

	return (     
        <article className={`post-classic ${classe}`}>
            <figure className="post-classic-figure">
                {link ? 
                    <img  src={url} alt={alt} width={width} height={height}/> : 
                    <img  src={url} alt={alt} width={width} height={height}/>} 
            </figure>
            <div className="post-classic-figcaption">
                <h3 className="post-classic-title">{title}</h3>
                <p className="post-classic-text">{description}</p>
                {link && <span className="link-arrow" href={link}>
                    <FontAwesomeIcon icon={faAngleRight} size="2x" />
                </span>}
            </div>
        </article>
	);
};

export default Box;