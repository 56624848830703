import React, { useEffect, useState, useRef } from 'react';
import "swiper/swiper.min.css";
import banner_01 from '../../assets/banner_01.jpg';
import banner_03 from '../../assets/banner_03.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import './style.scss';
import SwiperCore, { EffectFade,Navigation,Pagination,Autoplay } from 'swiper/core';

SwiperCore.use([EffectFade,Navigation,Pagination,Autoplay]);

const Banner = () => {

    const swiperRef = useRef(null)
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

    return (
        <div className="banner" 
            className={scroll ? "magno-navbar-nav--is-stuck-banner" : null}
            onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
            onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}>
             <Swiper
                ref={swiperRef} 
                centeredSlides={true}
                spaceBetween={30} 
                navigation={false} 
                pagination={{ "clickable": true }}  
                autoplay={{
                    delay: 4500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                className="mySwiper">
                <SwiperSlide>
                    <img
                        className="d-block w-100"
                        src={banner_01}
                        alt="Armários sob medida!"
                    />
                </SwiperSlide>
                 <SwiperSlide>
                    <img
                        className="d-block w-100"
                        src={banner_03}
                        alt="Sem espaço desperdiçado"
                    />
                </SwiperSlide>
            </Swiper>           
        </div>
    );
};

export default Banner;