import React from 'react';
import Address from '../components/address';
import Header from '../components/header';
import FooterContainer from '../components/footer';

const Localizacao = () => {
  return (
    <>
        <Header />
        <Address />
        <FooterContainer />
    </>
  );
}

export default Localizacao;
