import React, { useEffect, useState } from 'react';
import  './style.scss';
import Gallery from 'react-grid-gallery';
import { Container } from 'react-bootstrap';
import IMG_01 from '../../assets/services/IMG-01.jpg';
import IMG_02 from '../../assets/services/IMG-02.jpg';
import IMG_03 from '../../assets/services/IMG-03.jpg';
import IMG_04 from '../../assets/services/IMG-04.jpg';
import IMG_05 from '../../assets/services/IMG-05.jpg';
import IMG_06 from '../../assets/services/IMG-06.jpg';
import IMG_07 from '../../assets/services/IMG-07.jpg';
import IMG_08 from '../../assets/services/IMG-08.jpg';
import IMG_09 from '../../assets/services/IMG-09.jpg';
import IMG_10 from '../../assets/services/IMG-10.jpg';
import IMG_11 from '../../assets/services/IMG-11.jpg';
import IMG_12 from '../../assets/services/IMG-12.jpg';
import IMG_14 from '../../assets/services/IMG-14.jpg';
import IMG_15 from '../../assets/services/IMG-15.jpg';
import IMG_16 from '../../assets/services/IMG-16.jpg';
import IMG_18 from '../../assets/services/IMG-18.jpg';
import IMG_19 from '../../assets/services/IMG-19.jpg';
import IMG_20 from '../../assets/services/IMG-20.jpg';
import IMG_21 from '../../assets/services/IMG-21.jpg';
import IMG_22 from '../../assets/services/IMG-22.jpg';
import IMG_23 from '../../assets/services/IMG-23.jpg';
import IMG_24 from '../../assets/services/IMG-24.jpg';
import IMG_25 from '../../assets/services/IMG-25.jpg';
import IMG_26 from '../../assets/services/IMG-26.jpg';
import IMG_27 from '../../assets/services/IMG-27.jpg';
import IMG_28 from '../../assets/services/IMG-28.jpg';
import IMG_29 from '../../assets/services/IMG-29.jpg';
import IMG_30 from '../../assets/services/IMG-30.jpg';
import IMG_31 from '../../assets/services/IMG-31.jpg';
import IMG_32 from '../../assets/services/IMG-32.jpg';
import IMG_33 from '../../assets/services/IMG-33.jpg';
import IMG_34 from '../../assets/services/IMG-34.jpg';
import IMG_35 from '../../assets/services/IMG-35.jpg';
import IMG_36 from '../../assets/services/IMG-36.jpg';
import IMG_37 from '../../assets/services/IMG-37.jpg';
import IMG_38 from '../../assets/services/IMG-38.jpg';
import IMG_39 from '../../assets/services/IMG-39.jpg';

const IMAGES =
[
	{
		src: IMG_01,
		thumbnail: IMG_01,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_02,
		thumbnail: IMG_02,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_03,
		thumbnail: IMG_03,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_04,
		thumbnail: IMG_04,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_05,
		thumbnail: IMG_05,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_06,
		thumbnail: IMG_06,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_07,
		thumbnail: IMG_07,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_08,
		thumbnail: IMG_08,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_09,
		thumbnail: IMG_09,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_19,
		thumbnail: IMG_19,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_10,
		thumbnail: IMG_10,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_23,
		thumbnail: IMG_23,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_14,
		thumbnail: IMG_14,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_25,
		thumbnail: IMG_25,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_27,
		thumbnail: IMG_27,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_28,
		thumbnail: IMG_28,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_32,
		thumbnail: IMG_32,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_18,
		thumbnail: IMG_18,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_16,
		thumbnail: IMG_16,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_15,
		thumbnail: IMG_15,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_11,
		thumbnail: IMG_11,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_12,
		thumbnail: IMG_12,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_20,
		thumbnail: IMG_20,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_21,
		thumbnail: IMG_21,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_24,
		thumbnail: IMG_24,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},	
	{
		src: IMG_22,
		thumbnail: IMG_22,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_26,
		thumbnail: IMG_26,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_29,
		thumbnail: IMG_29,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_30,
		thumbnail: IMG_30,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_31,
		thumbnail: IMG_31,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_33,
		thumbnail: IMG_33,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_34,
		thumbnail: IMG_34,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_35,
		thumbnail: IMG_35,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_36,
		thumbnail: IMG_36,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_37,
		thumbnail: IMG_37,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_38,
		thumbnail: IMG_38,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_39,
		thumbnail: IMG_39,
		ththumbnailWidth: 320,
		thumbnailHeight: 212
	},
]

const ServiceGallery = () => {
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);
    return (
		<section className={`section section-sm subpages ${scroll ? "content-fixed" : ''}`}>          
			<Container>
				<h2>Serviços</h2>
				<p>
					Trabalhamos com móveis sob medida para todos ambientes, restauração, adegas, armários, balcões, banheiros, camas, cozinhas, prateleiras, quartos, racks, sala. 
				</p>
				<div>
					<Gallery images={IMAGES}/>
				</div>
			</Container>
		</section>
    );
};

export default ServiceGallery;