import React from 'react';
import './style.scss';
import Box from './box';
import { Link } from 'react-router-dom';
import post_01 from '../../assets/post_01.jpg';
import post_02 from '../../assets/post_02.jpg';
import post_03 from '../../assets/post_03.jpg';
import post_04 from '../../assets/post_04.jpg';
import banner_02 from '../../assets/banner_02.jpg';
import { Container, Row, Col } from 'react-bootstrap';

const Posts = () => {
	return (
        <section className="section section-xs bg-default section-posts">
            <Container>
				<Row className="row-30">
					<Col md={6} lg={4}>
                        <Box 
                            title="Melhores materiais" 
                            description="A Marcenaria Magno trabalha com o melhor MDF do mercado." 
                            image={{url:post_04, width:"370", height:"370", alt:"Melhores Materiais"}} 
                            classe="post-classic-mod-2"/>
                    </Col>
                    <Col md={6} lg={8}>
                        <Link to="/servicos">
                            <Box 
                                title="Serviços" 
                                link="true" 
                                description="Adegas, Armários, Balcões, Banheiros, Camas, Cozinhas, Prateleiras, Quartos, Racks e Sala." 
                                image={{url:banner_02, width:"770", height:"370", alt:"Serviços"}} />
                        </Link>
                    </Col>
                    <Col md={6} lg={4}>
                        <Box 
                            title="Restaurações" 
                            description="Fazemos a restauração em seu móvel antigo, veja nossas fotos." 
                            image={{url:post_01, width:"370", height:"370", alt:"Restaurações"}}/>
                    </Col>
                    <Col md={6} lg={4}>
                        <Box 
                            title="Ferramentas" 
                            description="Trabalhamos com as melhores ferramentas do mercado." 
                            image={{url:post_02, width:"370", height:"370", alt:"Ferramentas"}} 
                            classe="post-classic-mod-3"/>
                    </Col>
                    <Col md={6} lg={4}>
                        <Box 
                            title="Carinho e Dedicação" 
                            description="Estamos a mais de 30 anos no ramo de móveis planejados." 
                            image={{url:post_03, width:"370", height:"370", alt:"Carinho e Dedicação"}} 
                            classe="post-classic-mod-2"/>
                    </Col>
                </Row>
            </Container>
        </section>
	);
};

export default Posts;

