import React from 'react';
import  './style.scss';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Facebook, Whatsapp } from 'react-bootstrap-icons';

const FooterContainer = () => {

    const Footer = ({ children, ...restProps }) => {
        return <div {...restProps}>{children}</div>;
    }
    
    Footer.Wrapper = function FooterWrapper({children, ...restProps}) {
        return <div  {...restProps}>{children}</div>;
    }
    
    Footer.Row = function FooterRow({ children, ...restProps }) {
        return <div  {...restProps}>{children}</div>;
    };
    
    Footer.Column = function FooterColumn({ children, ...restProps }) {
        return <div  {...restProps}>{children}</div>;
    };
    
    Footer.Link = function FooterLink({ children, ...restProps }) {
        const { className, href } = {...restProps}
        return 	<Link to={href} className={className}>{children}</Link>;
    };
    
    Footer.Title = function FooterTitle({ children, ...restProps }) {
        return <div  {...restProps}>{children}</div>;
    };
      
   return ( 
        <>
            <Footer className="footer">
                <Footer.Wrapper className="wrapper">
                    <Container>
                        <Footer.Row className="row">
                            <Footer.Column className="column">
                                <Footer.Title className="title">Sobre Nós</Footer.Title>
                                 <Footer.Link className="link" href="/quemsomos">Quem Somos</Footer.Link>
                                 <AnchorLink offset={() => 100} className="link" href="#who-we-are">Nossa História</AnchorLink>
                            </Footer.Column>
                            <Footer.Column className="column">
                                <Footer.Title className="title">Portifólio</Footer.Title>
                                <Footer.Link className="link" href="/servicos">Serviços</Footer.Link>
                                <Footer.Link className="link" href="/projetos">Projetos</Footer.Link>
                            </Footer.Column>                       
                            <Footer.Column className="column">
                                <Footer.Title className="title">Localização</Footer.Title>
                                <Footer.Link className="link" href="/localizacao">
                                    End.: Avenida Armando <br/>
                                    Biagione, 386, <br/>
                                    Jd. Paulistano <br/>
                                    Araraquara/SP <br/> 
                                </Footer.Link>
                            </Footer.Column>
                            <Footer.Column>
                                <Footer.Title className="title">Contato</Footer.Title>
                                <Footer.Link className="link" href="/">Fone: (16) 3337-1933</Footer.Link>
                                <a className="link" target="_blank"  href="https://wa.me/5516997073135">WhatsApp: (16) 99707-3135</a>
                                <Footer.Link className="link" href="/">welinton@marcenariamagno.com.br</Footer.Link>
                            </Footer.Column>
                        </Footer.Row>                 
                    </Container>
                </Footer.Wrapper>    
            </Footer>
            <Footer className="copyright">
                <Footer.Wrapper className="wrapper">
                    <Container>                    
                        <Footer.Row className="row">                      
                            <Footer.Column>
                                <Footer.Link className="link" href="/">
                                    2021 © Marcenaria Magno - direitos reservados
                                </Footer.Link>
                            </Footer.Column>
                            <Footer.Column className="social">
                                <a target="_blank" className="facebook" href="https://www.facebook.com/marcenariamagno.com.br">
                                    <Facebook  size={27}/>                                  
                                </a>{" "}
                                 <a target="_blank" className="whatsapp"  href="https://wa.me/5516997073135">
                                    <Whatsapp  size={27}/>                                  
                                </a>
                            </Footer.Column>
                        </Footer.Row>
                    </Container>
                </Footer.Wrapper>
            </Footer>
        </>
    );
};

export default FooterContainer;