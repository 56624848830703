import React from 'react';
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';

const WhoWeAre = () => {
	return (
        <section id="who-we-are" className="section section-sm bg-gray-100">
            <Container>
                <h2>Nossa História</h2>
				<Row className="row-20">
					<Col md={12}>
                        <p>
                            A Marcenaria Magno nasceu em janeiro de 1986 pelas mãos do Sr. Wilson M. Magno e seu filho Sr. Wilton M. Magno os dois 
                            fundadores são marceneiros desde de quando começaram a trabalhar na vida, por isso a marcenaria faz parte de suas vidas. 
                        </p>
                        <p>
                            Hoje com Sr. Wilton e Welinton seu filho uma familia de marceneiros. 
                            Marcenaria  Magno tem o prazer de te convidar, estamos de portas abertas, para nos conhecer e contar com nosso 
                            conhecimento e tradição de fazer bem e com qualidade.
                        </p>
                    </Col>                    
                </Row>
            </Container>
        </section>
	);
};

export default WhoWeAre;

