import React from 'react';
import Header from '../components/header';
import AboutUs from '../components/about-us';
import FooterContainer from '../components/footer';

const QuemSomos = () => {
  return (
    <>
        <Header />
        <AboutUs />
        <FooterContainer />
    </>
  );
}

export default QuemSomos;
