import React, { useEffect, useState } from 'react';
import './style.scss';
import logo from '../../assets/logo.png';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = () => {
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
		<header>
			<Navbar bg="light" variant="light" expand="lg"  className={scroll ? "magno-navbar-nav--is-stuck fixed-top" : null}> 
				<Container>
					<Navbar.Brand href="/">
						<img
							src={logo}
							className="d-inline-block align-top"
							alt="Marcenaria Magno"
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="magno-navbar-nav" />
					<Navbar.Collapse id="magno-navbar-nav">
						<Nav className="mr-auto">
							<Link to="/" className="nav-link">Home</Link>
							<Link to="/quemsomos" className="nav-link">Quem Somos</Link>
							<Link to="/servicos" className="nav-link">Serviços</Link>
							<Link to="/projetos" className="nav-link">Projetos</Link>
							<Link to="/localizacao" className="nav-link">Localização</Link>
							<Link to="/contato" className="nav-link">Contato</Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
};

export default Header;