import React, { useEffect, useState } from 'react';
import Gallery from 'react-grid-gallery';
import { Container } from 'react-bootstrap';
import IMG_01 from '../../assets/projects/IMG_01.jpg';
import IMG_02 from '../../assets/projects/IMG_02.jpg';
import IMG_03 from '../../assets/projects/IMG_03.jpg';
import IMG_04 from '../../assets/projects/IMG_04.jpg';
import IMG_05 from '../../assets/projects/IMG_05.jpg';
import IMG_06 from '../../assets/projects/IMG_06.jpg';
import IMG_07 from '../../assets/projects/IMG_07.jpg';
import IMG_08 from '../../assets/projects/IMG_08.jpg';
import IMG_09 from '../../assets/projects/IMG_09.jpg';
import IMG_10 from '../../assets/projects/IMG_10.jpg';
import IMG_11 from '../../assets/projects/IMG_11.jpg';
import IMG_12 from '../../assets/projects/IMG_12.jpg';


const IMAGES =
[
	{
		src: IMG_01,
		thumbnail: IMG_01,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_02,
		thumbnail: IMG_02,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_03,
		thumbnail: IMG_03,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_04,
		thumbnail: IMG_04,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_05,
		thumbnail: IMG_05,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_06,
		thumbnail: IMG_06,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_07,
		thumbnail: IMG_07,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_08,
		thumbnail: IMG_08,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_09,
		thumbnail: IMG_09,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_10,
		thumbnail: IMG_10,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},	
	{
		src: IMG_11,
		thumbnail: IMG_11,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	},
	{
		src: IMG_12,
		thumbnail: IMG_12,
		thumbnailWidth: 320,
		thumbnailHeight: 212
	}
]

const Projects = () => {
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

    return (
		<section className={`section section-sm subpages ${scroll ? "content-fixed" : ''}`}>          
			<Container>
				<h2>Projetos</h2>
				<p>
					Desenhamos o seu projetos em 3D, do jeito do seu sonho, geramos o plano de corte das peças e lista de material a partir do desenho. Dando a você uma liberdade de escolha e economia.
				</p>
				<div>
					<Gallery images={IMAGES}/>
				</div>
			</Container>
		</section>
    );
};

export default Projects;