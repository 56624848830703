import React, { useEffect, useState } from 'react';
import post_03 from '../../assets/post_03.jpg';
import { Container, Row, Col, Image } from 'react-bootstrap';

const AboutUs = () => {
    const [scroll, setScroll] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
        <section className={`section section-sm subpages ${scroll ? "content-fixed" : ''}`}>
            <Container>
                <h2>Quem Somos</h2>
				<Row className="row-30">
                    <Col md={12} lg={12}>
                        <p>A Marcenaria Magno nasce da tradição de uma família com mais de 30 anos de artistas marceneiros.</p>                       
                    </Col> 
					<Col md={6} lg={6}>
                        <Image src={post_03} thumbnail />
                    </Col>   
                    <Col md={6} lg={6}>
                        <p>A experiência acumulada, o saber e a paixão pelas artes da madeira levam-nos a procurar a qualidade e durabilidade e a ter especial atenção ao detalhe.</p>
                        <p>Na  Marcenaria Magno trabalhamos com o melhor MDF (Medium-Density Fiberboard) do mercado, o que possibilida móveis de alta durabilidade.</p>
                        <p>Com oficina localizada em Araraquara-SP, todos os projetos incluem uma visita técnica e aconselhamento no local e valorizamos o cumprimento de prazos.</p>
                        <p>Cada peça tem uma história que nos inspira, e queremos ajudar a contar a sua. Junte-se a nós !</p>
                    </Col>                                     
                </Row>
            </Container>
        </section>
	);
};

export default AboutUs;
