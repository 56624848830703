import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Alert, Button } from 'react-bootstrap';

const Error = () => {
    let history = useHistory()
    
    const handleHome = () => {
        return history.push("/");
    }

	return (
        <section className="section section-sm bg-default">
            <Container>
                <h2>A página não foi encontrada.</h2>
                <br/><br/>
				<Alert variant="danger" >
                    <h2>404 - ERRO</h2><br/>
                    <p>
                        Lamentamos mas a página que pretende ver não foi encontrada. <br/>
                        A página pode ter sido desativada ou o endereço está incorreto. <br/>
                        Por favor, verifique o endereço e tente novamente.
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button  variant="danger" onClick={() => handleHome()}>
                           Ir para a Home
                        </Button>
                    </div>
                </Alert><br/>
            </Container>
        </section>
	);
};

export default Error;
