import React, { useEffect, useState } from 'react';
import Maps from '../maps';
import { Container, Row, Col } from 'react-bootstrap';

const Address = () => {
    const [scroll, setScroll] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
        <section className={`section section-sm subpages ${scroll ? "content-fixed" : ''}`}>
            <Container>
                <h2>Localização</h2>
				<Row className="row-30">
					<Col md={12} lg={12}>
                        <p>Avenida Armando Biagione, nº 386, Jardim Paulistano - Araraquara-SP</p>
                       <Maps width="100%" height="500"/>
                    </Col>                                      
                </Row>
            </Container>
        </section>
	);
};

export default Address;

