import React from 'react';
import Header from '../components/header';
import FooterContainer from '../components/footer';
import Projects from '../components/projects';

const Projetos = () => {
  return (
    <>
        <Header />
        <Projects />
        <FooterContainer />
    </>
  );
}

export default Projetos;
