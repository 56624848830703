import React from 'react';
import Header from '../components/header';
import FooterContainer from '../components/footer';
import ServiceGallery from '../components/service-gallery';

const Servicos = () => {
  return (
    <>
        <Header />
        <ServiceGallery />
        <FooterContainer />
    </>
  );
}

export default Servicos;
