import React, { useState } from 'react';
import Maps from '../components/maps';
import Posts from '../components/posts';
import Header from '../components/header';
import Banner from '../components/banner';
import Contact from '../components/contact';
import WhoWeAre from '../components/who-we-are';
import FooterContainer from '../components/footer';
import LoadingScreen from 'react-loading-screen';

const App = () => {

	const [loadingHome, setLoadingHome] = useState(true);

	return (
		<>
			<Header />
			<Banner />
			<Posts />
			<WhoWeAre />
			<Contact title="Contato"/>
			<Maps width="100%" height="250"/>
			<FooterContainer /> 
		</>
	);
}

export default App;
