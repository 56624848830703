import React, { useState, useCallback } from 'react';
import './style.scss';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import MaskedInput from "react-text-mask";
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';

const ERROR_MESSAGE_REQUIRED_FIELD = 'Obrigatório.';
const ERROR_MESSAGE_VALID_EMAIL = 'Informe um e-mail válido.';
const ERROR_MESSAGE_LIMIT_NUMBER_EXCEEDED = 'Limite de 250 caracteres.';
const SUCCESS_MESSAGE_SENDED = 'Mensagem enviada com sucesso.';
const ERROR_MESSAGE_SENDED = 'Erro ao enviar mensagem.';
const URL = 'https://marcenariamagno.com.br/api/index.php';

const CONTACT_VALIDATION = Yup.object({
	contact_name: Yup.string().required(ERROR_MESSAGE_REQUIRED_FIELD),
	contact_phone: Yup.string().required(ERROR_MESSAGE_REQUIRED_FIELD),
	contact_email: Yup.string().email(ERROR_MESSAGE_VALID_EMAIL).required(ERROR_MESSAGE_REQUIRED_FIELD),
	contact_message: Yup.string().max(250, ERROR_MESSAGE_LIMIT_NUMBER_EXCEEDED).required(ERROR_MESSAGE_REQUIRED_FIELD),
});

const phoneNumberMask = ["(",/[1-9]/,/\d/,")"," ",/\d/,/\d/,/\d/,/\d/,/\d/,"-", /\d/,/\d/,/\d/,/\d/];

const DefaultModal = ({ title, message, callback, handleClose, open } ) => {

	const handleCancel = useCallback(() => {
		callback();
		handleClose();
	}, [callback, handleClose]);

	return(
		<Modal show={open} onHide={handleCancel}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
		</Modal>
	);
}

const Contact = ({ title }) => {   

   	const initialValues = {
		contact_name: '',
		contact_phone: '',
		contact_email: '',
		contact_message: ''
	};	

	const [modalProps, setModalProps] = useState({
		callback: () => {},
		open: false,
		title: '',
		message: ''
	});

	const handleSubmit = async(values, {resetForm}) => {
		
		try {
			await axios.post(URL,values);
			setModalProps({
				callback: () => {},
				open: true,
				title: 'Sucesso',
				message: SUCCESS_MESSAGE_SENDED
			});
			resetForm({});
		} catch(err) {
			setModalProps({
				callback: () => {},
				open: true,
				title: 'Ops ...',
				message: ERROR_MESSAGE_SENDED
			});
			console.error(err);
		}    
    }

	return (<>
			<section className="section section-sm bg-default">
				<Container>
					<h2>{title}</h2>
					<Formik initialValues={initialValues} validationSchema={CONTACT_VALIDATION} onSubmit={handleSubmit}>  
						{({  
							handleSubmit,  
							handleChange,  
							handleBlur,  
							values,  
							touched,  
							isInvalid,  
							errors,
							resetForm  
						}) => (  
							<>
								<Form noValidate onSubmit={handleSubmit} className="form-container"> 
									<Row className="row-30">
										<Col xs={12} md={4} lg={4}>
											<Form.Control 
												type="text"  
												name="contact_name"  
												id="contact_name" 
												placeholder="Nome"  
												value={values?.contact_name}  
												onChange={handleChange}  
												onBlur={handleBlur}  
												isInvalid={touched?.contact_name && errors?.contact_name} 
											/>
											<Form.Control.Feedback type="invalid">
												{errors?.contact_name}
											</Form.Control.Feedback>
										</Col>
										<Col xs={12} md={4} lg={4}>
											<Field
												name="contact_phone"
												isInvalid={touched?.contact_phone && errors?.contact_phone} 
												render={({ field }) => (
													<MaskedInput
														{...field}
														mask={phoneNumberMask}
														id="contact_phone"
														placeholder="Telefone"
														type="text"
														onChange={handleChange}
														onBlur={handleBlur}
														className={
															errors.contact_phone && touched.contact_phone
															  ? "form-control is-invalid"
															  : "form-control"
														}
													/>
												)}
											/>
											<Form.Control.Feedback type="invalid">
												{errors?.contact_phone}
											</Form.Control.Feedback> 
										</Col>
										<Col xs={12} md={4} lg={4}>
											<Form.Control 
												type="text"  
												name="contact_email"  
												id="contact_email" 
												placeholder="E-Mail"  
												value={values?.contact_email}  
												onChange={handleChange}  
												onBlur={handleBlur}  
												isInvalid={touched?.contact_email && errors?.contact_email} 
											/>
											<Form.Control.Feedback type="invalid">
												{errors?.contact_email}
											</Form.Control.Feedback>
										</Col>						
									</Row>
									<Row>
										<Col md={12} lg={12}>
											<Form.Control 
												as="textarea" rows={3}
												type="text" 
												maxLength="250" 
												name="contact_message"  
												id="contact_message" 
												placeholder="Mensagem"  
												value={values?.contact_message}  
												onChange={handleChange}  
												onBlur={handleBlur}  
												isInvalid={touched?.contact_message && errors?.contact_message} 
											/>
											<Form.Control.Feedback type="invalid">
												{errors?.contact_message}
											</Form.Control.Feedback>
										</Col>                   
									</Row> 
									<div className="form-button group-sm">                  
										<Button 
											variant="primary" 
											type="submit" 
											className="button button-primary">
												Enviar
										</Button>
									</div>
								</Form> 
							</>
						)}  
					</Formik>
				</Container>
			</section>
			<DefaultModal 
				{...modalProps}
				handleClose={() =>
					setModalProps({ ...modalProps, open: false })
				}
			/>		
		</>
	);
};

export default Contact;

