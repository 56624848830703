import React from 'react';
import Contact from '../components/contact';
import Header from '../components/header';
import FooterContainer from '../components/footer';

const Contato = () => {
  return (
    <>
        <Header />
        <Contact title="Contato"/>
        <FooterContainer />
    </>
  );
}

export default Contato;
